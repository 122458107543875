import { isString } from "~/resources/utilities";

export default {
  methods: {
    stringContentExists(translationKey) {
      return (
        this.$te(translationKey, this.$i18n.fallbackLocale) &&
        this.$t(translationKey).length > 0 &&
        this.$t(translationKey) !== translationKey
      );
    },
    objectContentExists(translationKey) {
      return !isString(this.$tm(translationKey));
    },
    parseArrayContent(translationKey) {
      const content = this.$tm(translationKey);
      
      return isString(content) ? [] : Object.values(content);
    },
  },
};
